import {graphql, useStaticQuery} from "gatsby"


const usePrivacy = () => {
    const response = useStaticQuery(
        graphql`
            query privacity {
                strapiPrivacy {
                    ContentBody
                }
            }
        `
    )
    return response.strapiPrivacy.ContentBody;
}

export default usePrivacy;
