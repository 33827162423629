import Layout from "../components/Layout";
import NavThree from "../components/NavThree";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import React from "react";
import PageDetails from "../components/PageDetails";
import usePrivacy from "../Hooks/usePrivacy";
import useLegalAdvice from "../Hooks/useLegalAdvice";

const AvisoLegal = () => {
    const privacyData = useLegalAdvice();
    return (
        <Layout pageTitle="Kipso | News Details">
            <NavThree active={"privacidad"}/>
            <PageHeader title="Privacidad"/>
            <PageDetails body={privacyData}/>
            <Footer/>
        </Layout>
    );
};

export default AvisoLegal;
