import React from "react";

const PageDetails = ({body}) => {
  return (
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-one__single">
                <div className="page-one__content text-justify">
                  <p className="blog-one__text">
                    <div dangerouslySetInnerHTML={{__html: body}}/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default PageDetails;
