import {graphql, useStaticQuery} from "gatsby"


const useLegalAdvice = () => {
    const response = useStaticQuery(
        graphql`
            query legalAdvice {
                strapiLegalAdvice {
                    bodycontent
                }
            }
        `
    )
    return response.strapiLegalAdvice.bodycontent;
}

export default useLegalAdvice;
